import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on2 = ref.on;
var attrs2 = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","color":"primary"}},'v-btn',attrs,false),on),[_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs2,false),on2),[_vm._v("mdi-swap-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltipText[_vm.reglement.status]))])])]}}])},[_c(VSheet,[(_vm.reglement.status === null && _vm.saisie_type !== 'encaissement')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'waiting')}}},[_vm._v("Mettre en attente de paiement ")]):_vm._e(),(_vm.reglement.status === null && _vm.saisie_type !== 'decaissement')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'waiting_payment')}}},[_vm._v("Mettre en attente d'encaissement ")]):_vm._e(),(_vm.reglement.status === 'waiting')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'valid')}}},[_vm._v("Valider la facture ")]):_vm._e(),(_vm.reglement.status === 'waiting_payment')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'paid')}}},[_vm._v(" Marquer comme payé ")]):_vm._e(),(_vm.reglement.status === 'valid')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'check')}}},[_vm._v("Mise en paiement ")]):_vm._e(),(_vm.reglement.status === 'check')?_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.updateReglement(_vm.reglement, 'paid')}}},[_vm._v("Marquer comme payé ")]):_vm._e(),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }